import React, { useEffect, useState } from "react";
import { Box, Button, Center, Container, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const DownloadPage = () => {
  const [items, setItems] = useState([]);
  const [showAd, setShowAd] = useState(true);

  const handleCardClick = () => {
    window.open("https://loanwaystore.com//", "_blank");
  };

  useEffect(() => {
    const images = [
      { src: "Instant-Download1.png", alt: "Instant Download" },
      { src: "Cloud-Logo.png", alt: "Cloud Logo" },
      { src: "Hubdrive2.png", alt: "Hubdrive" },
      { src: "GDFlix.png", alt: "GDFlix" },
      { src: "gofile.png", alt: "GoFile" },
    ];

    const links = [
      "https://atozmovies.store/movie-download-evh7fy7vhe7vr7e8e7c-evervevevevre8vuev0e--dvfvrefhfhfh-ef9urfj-vjrv",
      "https://loanwaystore.com/",
      "https://atozmovies.store/movie-download-evh7fy7vhe7vr7e8e7c-evervevevevre8vuev0e--dvfvrefhfhfh-ef9urfj-vjrv",
      "https://loanwaystore.com/",
      "https://atozmovies.store/movie-download-evh7fy7vhe7vr7e8e7c-evervevevevre8vuev0e--dvfvrefhfhfh-ef9urfj-vjrv",
      "https://loanwaystore.com/",
    ];

    const shuffledImages = images.sort(() => Math.random() - 0.5);
    const shuffledLinks = links.sort(() => Math.random() - 0.5);

    const combinedItems = shuffledImages.map((image, index) => ({
      ...image,
      link: shuffledLinks[index],
    }));

    setItems(combinedItems);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAd(false);
    }, 55000);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseAd = () => {
    setShowAd(false);
  };

  return (
    <Box background="black" height="100vh">
      <Box>
        <span className="nav-item" onClick={handleCardClick}>
          <a
            href="https://loanwaystore.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button color="white" marginRight={2} colorScheme="">
              HUBLINKS
            </Button>
          </a>
        </span>
        <hr />
      </Box>
      <Box>
        <Container background="white" marginBottom={20} width="98%">
          <Text color="black" padding={1} marginTop={3}>
            Note: Use Chrome / iDM /ADM For better Downloading Experience.
          </Text>
          <hr style={{ color: "black" }} />
          {items.map((item, index) => (
            <React.Fragment key={item.src}>
              <Center>
                <div style={{ textAlign: "center" }}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={item.src}
                      alt={item.alt}
                      style={{ width: "160px" }}
                    />
                  </a>
                </div>
              </Center>
              <hr style={{ color: "black" }} />
            </React.Fragment>
          ))}
          <Center>
            <div style={{ textAlign: "center" }}>
              <a href="https://telegram.me/">
                <img
                  src="TeleGram-Download.png"
                  alt="TeleGram-Download"
                  style={{ width: "160px" }}
                />
              </a>
            </div>
          </Center>
          <hr style={{ color: "black" }} />
        </Container>
      </Box>
      {/* Footer Ad */}
      <Box
        as="footer"
        position="fixed"
        bottom="0"
        width="100%"
        background="black"
        padding="10px"
        textAlign="center"
      >
        <Box position="absolute" top="10px" right="10px">
          <Link to="https://loanwaystore.com/" target="_blank">
            <Button colorScheme="" onClick={() => setShowAd(false)}>
              X
            </Button>
          </Link>
        </Box>
        {showAd && (
          <Link to="https://loanwaystore.com/" target="_blank">
            <Box>
              <Image
                src="https://refpa7921972.top/img/AdAgent_1/d0daa1d5-d517-455b-986b-3b49e7ab67a3.gif"
                alt="Ad Banner"
                width="100%"
                height="auto"
                onClick={() => setShowAd(false)}
              />
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default DownloadPage;
